import React from 'react';

import listArrow from '@/newcar/ui/common/img/object/icon/arrow-icon-black.svg';

import * as styles from './CarModelRankingPanel.module.styl';

type CarModelRankingPanelProps = {
  rankNumber: number,
  modelId: string,
  makerName: string,
  modelName: string,
  fuel: { name: string, value: string },
  price: string,
  photo: string,
}
const CarModelRankingPanel = (props: CarModelRankingPanelProps): JSX.Element => {
  const {
    rankNumber, modelId, makerName, modelName, fuel, price, photo,
  } = props;

  let rankingStyle = '';
  switch (rankNumber) {
    case 1:
      rankingStyle = styles.rankingNo1;
      break;
    case 2:
      rankingStyle = styles.rankingNo2;
      break;
    case 3:
      rankingStyle = styles.rankingNo3;
      break;
    default:
      rankingStyle = styles.rankingBasic;
      break;
  }

  return (
    <div className="is-hidden-tablet" key={`ranking-panel-mobile-${modelId}`}>
      <a href={`/detail/${modelId}`}>
        <div className={`${styles.cardBox}`}>
          <div className={`${styles.cardBoxFigureContainer} column card-image is-padding-1`}>
            <figure className={`column ${styles.image}`}><img src={photo} alt="" width="240px" height="130px" /></figure>
          </div>
          <div className="column card-content is-padding-1">
            <p className={`${styles.hasTextGrey} is-size-7 has-text-weight-semibold`}>{makerName}</p>
            <p className={`${styles.isMarginAdjust} is-margin-top-1 is-padding-right-3`}><strong className="is-size-4">{modelName}</strong></p>
            <p className="is-margin-top-1">
              <span className="is-size-3 has-text-primary has-text-weight-bold is-margin-right-1">{fuel.value}</span>
              <span className="is-size-6 has-text-grey-light">{`km/L(${fuel.name})`}</span>
            </p>
            <p className="is-margin-top-1">
              <span className="is-size-3 has-text-weight-semibold is-margin-right-1">{price}</span>
              <span className="is-size-6 has-text-grey-light">円〜/月</span>
            </p>
          </div>
          <span className={styles.listArrow}><img src={listArrow} alt="" /></span>
          <div className={rankingStyle}><span>{rankNumber}</span></div>
        </div>
      </a>
    </div>
  );
};

export default CarModelRankingPanel;
